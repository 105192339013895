/* 
 * Duff Framework
 * ©Selltec Communications GmbH
 */

@import "definitions";

$entries_list_single_mar_left: 4.5em;                   
$entries_list_icon_highlight_color: $highlight_color;
$list_actions_color: #aaa;                              

/*Liste*/

div.list div.entries_list_heading{
    display: inline-block;
    vertical-align: middle;
    margin-right: $mar;
    > span:not(:first-child){
        margin-left: $mar_tiny; 
    }
}
.list_descriptions>div {
    background-color: #eee;
    padding: $pad_small $pad;
    margin: $mar_small 0;
}

div.entries_list table{
    margin: $mar 0;
    border: none;
    max-width: 100%;
    width: 100%;
    > thead{   
        > tr:last-child {
            &, td, th {
                border-bottom: $border;
            }
        }
        th {
            text-align: left;
            padding: $pad_small;
            white-space: nowrap;
            &.sortable {
                cursor: pointer;
            }
        }
    }

    th, td {
        .down{
            @include sl-icon($fa-var-chevron-down);
            margin-left: $mar_tiny;
            cursor: pointer;
        }
        .up{
            @include sl-icon($fa-var-chevron-up);
            margin-left: $mar_tiny;
            cursor: pointer;
        }
        .left{
            @include sl-icon($fa-var-chevron-left);
            margin-left: $mar_tiny;
            cursor: pointer;
        }
        .right{
            @include sl-icon($fa-var-chevron-right);
            margin-left: $mar_tiny;
            cursor: pointer;
        }
        .disabled{
            color:#ddd;
            cursor: not-allowed;
        }
    }

    &.no_head {
        margin-top: 0;
    }

    &:not(.no_head):not(.add_in_list) {
        > tbody{
            &:nth-child(odd)>tr,  &:nth-child(2n+1) > tr{
                background-color:$theme_color_light;
            }
        }
    }

    &.no_head:not(.add_in_list) {
        > tbody{
            &:nth-child(even)>tr,  &:nth-child(2n) > tr{
                background-color:$theme_color_light;
            }
        }
    }

    &.add_in_list > tbody > tr > td {
        border-bottom: $border_bright;
    }

    > tbody{
       
        > tr > td{
            padding: $pad_small;
            vertical-align: middle;
            white-space: nowrap;
            &.wide{
                white-space: normal;
                width: 90%;
            }            
            &.medium{
                white-space: normal;
                width: 50%;
            }

            &.list_actions {
                .add{
                    @include sl-icon($fa-var-plus);
                    color: $base_font_color; 
                    margin-right: $mar_small; 
                    cursor: pointer;
                }
                .view{
                    @include sl-icon($fa-var-search);
                    color: $base_font_color; 
                    margin-right: $mar_small; 
                    cursor: pointer;
                }
                .edit {
                    @include sl-icon($fa-var-pencil);
                    color: $base_font_color;
                    margin-right: $mar_small;
                    cursor: pointer;
                }
                .copy{
                    @include sl-icon($fa-var-copy);
                    color: $base_font_color;
                    margin-right: $mar_small;
                    cursor: pointer;
                }
                .download{
                    @include sl-icon($fa-var-download);
                    color: $base_font_color;
                    margin-right: $mar_small;
                    cursor: pointer;
                }
                .delete{
                    @include sl-icon($fa-var-trash);
                    color: $base_font_color;
                    margin-right: $mar_small;
                    cursor: pointer;
                }
                .open{
                    @include sl-icon($fa-var-arrow-down);
                    color: $base_font_color;
                    margin-right: $mar_small;
                    cursor: pointer;
                    &.active{
                        color: $entries_list_icon_highlight_color;
                    }
                }
                .git{
                    @include sl-icon($fa-var-git);
                }
                .pick{
                    @include sl-icon($fa-var-search-plus);
                } 
            }
            
            .checkbox{
                @include sl-icon($fa-var-square-o);
            }
            .check{
                @include sl-icon($fa-var-check-square-o);
            }
            
            .editable_cell .check,
            .editable_cell .checkbox {
                cursor: pointer;
            }

            .add_to_basket{
                @include sl-icon($fa-var-star-o);
                color: $theme_color;
                margin-left: $mar_small;
                cursor: pointer;
                font-size: 14px;

            }
            .remove_from_basket{
                @include sl-icon($fa-var-star);
                color: $theme_color;
                margin-left: $mar_small;
                cursor: pointer;
                font-size: 14px;
            }
            .list_editable {
                display: inline-block;
                min-width: 3em;
                min-height: 1em;

                > div {
                    padding-right: 0.4em;
                    margin-right: 1em;
                    display: inline-block;
                }
                span.edit{
                    display: none;
                    margin-right: 0;
                    color: darken($theme_color, 20%);
                }
            }
            .list_editable:hover {
                > div {
                    margin-right: 0;
                }

                span.edit {
                    min-width: 1em;
                    display: inline-block;
                    @include sl-icon($fa-var-pencil);
                    color: $theme_color;
                    cursor: pointer;
                }
            }
            .form_field .pick{
                @include sl-icon($fa-var-search); 
            }

            .form_field_currency {
                text-align: right;                
            }

            .grouped_categories_list {
                .subs {
                    white-space: normal;
                    .sub {
                        white-space: nowrap;
                        .sep {
                            white-space: normal;
                        }
                    }
                }                
            }
        }
    }
    > tfoot{   
        > tr:first-child{
            border-top: $border;
        }

        .form_field_currency {
            text-align: right;                
        }
    }
}

div.entries_list.single_list_entry {
    > table > tbody > tr > td:nth-child(2) {
        width:100%;
    } 
}

div.entries_list tbody.in_list_add > tr > td >  button.button {
    margin: 0;
}


.layout_form_content .layout_form_property > .form_field_input div.entries_list tbody.in_list_add,
div.entries_list tbody.in_list_add{
    input[type="text"] {
        max-width:100%;
    }

    .select_autocomplete {
        width:100%;        
    }
}

.entries_list .pdf_export {
    table > tbody{
        &:nth-child(odd)>tr,  &:nth-child(2n+1) > tr{
            background-color: #fff;
            > td{
                width: 50%;
                vertical-align: top;
            }
        }
    }
    table.fields > tbody{
        border: $border;
        tr{
            cursor: pointer;
            &:nth-child(even){
                background-color:$theme_color_light;
            }
        }
    }
}

.expanding_cell{
    width: 99%;
}


table.entries_list {
    margin: $mar 0;
    max-width: 100%;
    width: 100%;
    >thead>tr{
        >* {
            width:1%;
            &.sortable>* {
                cursor: pointer;
            }
            &.expanding_cell {
                width:auto;
            }
        }   
        &:last-child{
            border-bottom: $border;
        }
    }
}

table.no_expand {
    >thead>tr>th{
        white-space: normal;
        span {
            display:flex;
        }
        
        &.expanding_cell {
            width:0%;
        }
    }

    >tbody>tr>td {
        &.expanding_cell {
            width:0%;
        }
    }
}

td.list_actions {
    color: $list_actions_color;
    white-space: nowrap;
    >* {
        margin-right: $mar_tiny;
        cursor: pointer;
    }
}

/*Liste mit Einzelansicht*/

div.entries_list_single{
    margin-left: $entries_list_single_mar_left;
    padding: $pad 0;
}

/*Template bearbeiten*/

.base_info{
    margin-bottom: $mar;
}

div.list {
    div.entries_actions, div.list_modes {
        display: inline-block;
        margin-right: $mar;
        vertical-align: middle;

        &>div {
            display:inline-block;
        }
    }
}

div.view {
    div.entries_actions{
        display:flex;
        justify-content: space-between;
        margin-bottom: $mar_small;

        >div>div {
            display:inline-block;        
        }
    }
}


/*Vorschaubilder Liste - Tooltip*/

.preview_icon {
    position:relative;
    display:inline-block;
    >img {
        cursor: pointer;
    }
}

.google_maps {
    .infocontent {
        .form_wide > div .form_field_label,
        .form_wide > div.form_group > div .form_field_label{
            min-width:0;
        }

        //dirty hack to not show tooltip in infowindows
        .preview_icon .tooltip img {
            display:none;
        }
    }
    .marker_label {
        color: black;
        font-size: 1.1em;
        font-weight: bold;
        background-color: #eee;
        padding: 1px 4px 0px 3px;
        border: 1px solid #aaa;
    }
}

.preview_icon .tooltip {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 100%;
    margin-left: $mar;
    width: auto;
    height: auto;
        
    img {
        margin-top:-50%;
        background-color: #fff;
        border: solid 1px #aaa;
    }
}

.situation_editor .tabs{
    margin-top: $mar;    
}

/*Merkzettel*/

.entries_baskets{
    .heading{
        margin: $mar 0;
    }
}


div.list div.binary_dl{
    cursor:pointer;
    > a {
        display: inline-block;

        .download {
            padding-left: $pad_tiny;
            @include sl-icon($fa-var-download);
        }
    }
}

/*Tags*/

.type_taxonomy{
    > div {
        display: inline-block;
        margin-right: $mar_big;
    }    
}

.type_options .options_picker{
    display: inline-block;    
}


div.editor_main_area {

    /* Alias Hint in edit action */
    div.edit_entry_action {
        div.alias_hint {
            padding: $pad;
            padding-bottom: 0;
        }
        span.alias_label {
            cursor: pointer;
            color: $highlight_color;
        }
        span.alias_label:hover {
            font-weight: bold;
        }
    }

    /* Editor content and admin actions */

    .main_bg.main_wrapper {
        display: flex;

        > .container_form {
            width: 80%;
        }

        > .container_actions {
            width: 20%;
            padding: 1em;
            border: $border;
            border-left: none;

            .heading {
                margin-bottom: $mar;
            }
            .admin_action {
                margin-bottom: $mar;
            }
        }
    }
}

.type_categories{

    td {
        vertical-align: top;

        &:first-child {
            padding-left:0;
        }

        &:last-child {
            padding-right:0;
        }
    }

    tr:not(:last-child){
        td {
            padding-bottom:1em;
            border-bottom:solid $border-color 1px;
        }
    }
    
    tr:nth-child(n+3){
        td {
            padding-top:1em;
        }
    }
}

.bullet_points {
    margin-bottom: 1em;
    .main_point{
        display:flex;
        >div{
            margin-right:5px;
        }
    }
    .child_group .child_point{
        margin-left:10px;
        display:flex;
        >div{
            margin-right:5px;
        }
    }
}
        
div.simple_dropdown {
    display:none;
    position:absolute;
    left:0;
    top:100%;
    z-index:1000;
    
    &.active {
        display:inline-block;
    }

    border: solid 1px #ddd;
    background-color:#fff;
    
    >div {
        cursor: pointer;
        padding: .5em;
        border-bottom: solid 1px #ddd;
        white-space: nowrap;

        &:last-child {
          border-bottom:none;   
        }

        &:hover {
            background-color:#ccc;
        }

        &.selected {
            background-color:#ccc;
        }
    }
}

.entries_matrix {

    .selection_all {
        margin: $mar-small 0;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;

        &_entry {
            width: 130px;
            padding: $pad_small;
            border: $border;
            margin: $mar_small;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .preview_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100px;

                    
                img.preview_image {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .list_utils {
        display: flex;
        justify-content: space-between;
    }

    .list_actions {
        .add{
            @include sl-icon($fa-var-plus);
            color: $base_font_color; 
            margin-right: $mar_small; 
            cursor: pointer;
        }
        .view{
            @include sl-icon($fa-var-search);
            color: $base_font_color; 
            margin-right: $mar_small; 
            cursor: pointer;
        }
        .edit {
            @include sl-icon($fa-var-pencil);
            color: $base_font_color;
            margin-right: $mar_small;
            cursor: pointer;
        }
        .copy{
            @include sl-icon($fa-var-copy);
            color: $base_font_color;
            margin-right: $mar_small;
            cursor: pointer;
        }
        .download{
            @include sl-icon($fa-var-download);
            color: $base_font_color;
            margin-right: $mar_small;
            cursor: pointer;
        }
        .delete{
            @include sl-icon($fa-var-trash);
            color: $base_font_color;
            margin-right: $mar_small;
            cursor: pointer;
        }
    }
}